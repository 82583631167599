<template>
  <form action="" @submit.prevent="onSubmitSearch">
    <template v-if="!company">
      <default-loading :minHeight="'340px'"></default-loading>
    </template>
    <template v-else>
      <div class="section-header-left mb-4 searchbar">
        <h2>
          {{
            $t("home.What_Would_You_Like_To_Learn_Today_Username").replace(
              ":NAME",
              current_user.name
            )
          }}
        </h2>
        <div class="position-relative">
          <div class="
            input-items-group
            d-flex
            justify-content-between
            align-items-center
          ">
            <input ref="searchInput" v-model="search" :placeholder="$t('filters.search')" autocomplete="off" class="mt-2"
              type="text" @blur="inputBlur" @focus="modal = true" />
            <button class="btn" type="submit">
              <i class="uil-search"></i>
            </button>
          </div>
          <div v-if="modal" class="list-group mt-1 position-absolute w-100 z-100 shadow-lg searchbar-result">
            <div class="list-group-item list-group-item-action p-3" style="max-height: 350px; overflow-x: auto">

              <!-- LOADING BOX -->
              <default-loading v-if="searching" :minHeight="'340px'"></default-loading>
              <template v-else>
                <!-- SEARCH ITEMS -->
                <div>
                  <h4 class="mt-2 mb-2">
                    <i class="uil-book-open"></i> {{ $t('sideNav.my_assigned_education') }} ({{
                      allItems.assignments_total }})
                  </h4>
                  <template v-for="(assignment, index) in allItems.assignments">
                    <a :key="'search_post_list_item_' + index" class="list-group-item list-group-item-action"
                      href="javascript:void(0)" @click="openAssignmentDetails(assignment.id)">
                      {{ assignment.name }}
                    </a>
                  </template>
                </div>
                <div v-if="CollectionVisible">
                  <h4 class="mt-4 mb-2"><i class="uil-notebooks"></i> {{ $t("sideNav.My_collections") }} ({{
                    allItems.collections_total
                  }})</h4>
                  <template v-for="(collection, index) in allItems.collections">
                    <a :key="'search_collection_list_item_' + index" class="list-group-item list-group-item-action"
                      @click="openCollectionDetails(collection.id)">
                      {{ collection.name }}
                    </a>
                  </template>
                </div>
                <div v-if="socialPostVisible">
                  <h4 class="mt-4 mb-2"><i class="uil-coffee"></i> {{ $t("home.Wall") }} ({{ allItems.posts_total }})
                  </h4>
                  <template v-for="(post, index) in allItems.posts">
                    <a :key="'search_post_list_item_' + index" class="list-group-item list-group-item-action"
                      @click="openSocialPost(post.id)">
                      {{ post.text }}
                    </a>
                  </template>
                </div>
                <div>
                  <h4 class="mt-4 mb-2">{{ $t('home.provider_trainings') }}</h4>
                  <template v-for="(result, index) in allItems.providerResults">
                    <a :key="'search_provider_list_item_' + index" class="list-group-item list-group-item-action"
                      @click="openProviderDetails(result)">
                      {{ result.text }}
                    </a>
                  </template>
                </div>
                <div>
                  <h4 class="mt-4 mb-2">{{ $t("education.catalog_education") }} ({{
                    allItems.catalogResultsTotal
                  }})</h4>
                  <template v-for="(result, index) in allItems.catalogResults">
                    <a :key="'search_provider_list_item_' + index" class="list-group-item list-group-item-action"
                      @click="openCatalogDetails(result.id)">
                      {{ result.text }}
                    </a>
                  </template>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </template>
  </form>
</template>

<script>
import store from "@/core/services";
import seraching_module, { GET_ITEMS, LOADING, MODULE_NAME } from "@/core/services/store/searching.module";
import DefaultLoading from "@/view/components/loading/DefaultLoading";
import axios from "axios";
import { getSubDomainId } from "@/core/services/store.js";
import company_module, {
  MODULE_NAME as COMPANY_MODULE_NAME,
  SELF as COMPANY_SELF,
} from "@/core/services/store/company.module";

export default {
  name: "Searching",
  components: { DefaultLoading },
  data() {
    return {
      assignmentUrl: "api/assignment",
      socialPostUrl: "api/social-post",
      collectionUrl: "api/collection",
      providerSearchUrl: "api/provider-search",
      catalogSearchUrl: "api/training/user-assigned/search",
      search: "",
      page: 1,
      per_page: 5,
      order: "desc",
      sort: "id",
      assignmentOrder: "id",
      assignmentSort: "desc",
      modal: false,
      posts: null,
      assignments: null,
      collections: null,
      providerResults: null,
      catalogResults: null
    };
  },
  props: {
    searchValue: ""
  },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }

    registerStoreModule(MODULE_NAME, seraching_module);
    registerStoreModule(COMPANY_MODULE_NAME, company_module);
  },
  computed: {
    company: {
      get() {
        return store.getters[COMPANY_MODULE_NAME + "/" + COMPANY_SELF];
      },
      set(value) {
      },
    },
    searching: {
      get() {
        return this.$store.getters[MODULE_NAME + "/" + LOADING];
      },
      set(value) {
      },
    },
    current_user() {
      return JSON.parse(this.secureLocalStorage('user'));
    },
    formattedAssignments() {
      let results = [];
      if (this.assignments && this.assignments.hasOwnProperty('data')) {
        this.assignments.data.forEach((currentItem) => {
          results.push({
            id: currentItem.id,
            name: currentItem.name,
            cover_image_link: currentItem.cover_image_link,
            description: currentItem.description ? currentItem.description : "-",
          });
        });
      }
      return results;
    },
    formattedProviderResults() {
      let results = [];
      if (this.providerResults && this.providerResults.hasOwnProperty('data')) {
        this.providerResults.data.forEach((currentItem) => {
          results.push({
            id: currentItem.id,
            text: currentItem.title,
            provider: currentItem.provider
          });
        });
      }
      return results;
    },
    formattedCatalogResults() {
      let results = [];
      if (this.catalogResults.length > 0) {
        this.catalogResults.forEach((currentItem) => {
          results.push({
            id: currentItem.id,
            text: currentItem.name,
          });
        });
      }
      return results;
    },
    formattedPosts() {
      let results = [];
      if (this.posts && this.posts.hasOwnProperty('data')) {
        this.posts.data.forEach((currentItem) => {
          results.push({
            id: currentItem.id,
            text: currentItem.text,
          });
        });
      }
      return results;
    },
    formattedCollections() {
      let results = [];
      if (this.collections && this.collections.my_collections) {
        this.collections.my_collections.data.forEach((currentItem) => {
          results.push({
            id: currentItem.id,
            name: currentItem.name,
          });
        });
      }

      return results;
    },
    allItems() {
      let posts = this.posts;
      let assignments = this.assignments;
      let collections = this.collections ? this.collections.my_collections : null;
      let providerResults = this.providerResults ? this.providerResults : null;
      let catalogResults = this.catalogResults ? this.catalogResults : null;
      return {
        posts: this.formattedPosts,
        assignments: this.formattedAssignments,
        collections: this.formattedCollections,
        providerResults: this.formattedProviderResults,
        catalogResults: this.formattedCatalogResults,
        posts_total: posts ? posts.total : 0,
        assignments_total: assignments ? assignments.total : 0,
        collections_total: collections ? collections.total : 0,
        providerResultsTotal: providerResults ? providerResults.length : 0,
        catalogResultsTotal: catalogResults ? catalogResults.length : 0,
      };
    },
  },
  methods: {
    inputBlur: _.debounce(function () {
      this.modal = false;
    }, 150),

    getAssignmentResult() {
      let self = this;
      this.$store
        .dispatch(MODULE_NAME + "/" + GET_ITEMS, {
          url: this.assignmentUrl,
          filters: {
            search: self.search,
            page: self.page,
            per_page: self.per_page,
            sort: self.assignmentSort,
            order: self.assignmentOrder,
            by_user: true,
            with_expired: false
          },
          showLoading: true,
        })
        .then((result) => {
          if (result.status) {
            self.assignments = result.data;
          }
        });
    },
    getCollectionResult() {
      if (!this.CollectionVisible) {
        return false;
      }
      let self = this;
      this.$store
        .dispatch(MODULE_NAME + "/" + GET_ITEMS, {
          url: this.collectionUrl,
          filters: {
            search: self.search,
            page: self.page,
            per_page: self.per_page,
            sort: self.order,
            order_by: self.sort,
          },
          acceptPromise: false,
          showLoading: true,
        })
        .then((result) => {
          self.collections = result.data;
        });
    },
    getSocialPosts() {
      if (!this.socialPostVisible) {
        return false;
      }
      let self = this;
      this.$store
        .dispatch(MODULE_NAME + "/" + GET_ITEMS, {
          url: this.socialPostUrl,
          filters: {
            like: self.search,
            page: self.page,
            per_page: self.per_page,
            sort: self.sort,
            order: self.order,
          },
          showLoading: true,
        })
        .then((result) => {
          if (result.status) {
            self.posts = result.data;
          }
        });
    },
    getSearchProviderResult() {
      let self = this;
      this.$store
        .dispatch(MODULE_NAME + "/" + GET_ITEMS, {
          url: this.providerSearchUrl,
          filters: {
            q: self.search
          },
          showLoading: true,
        })
        .then((result) => {
          if (result.data.data.length) {
            self.providerResults = result.data;
          }
        });
    },
    getCatalogResult() {
      let self = this;
      this.$store
        .dispatch(MODULE_NAME + "/" + GET_ITEMS, {
          url: this.catalogSearchUrl,
          filters: {
            search: self.search
          },
          showLoading: true,
        })
        .then((result) => {
          if (result?.data?.length > 0) {
            self.catalogResults = result.data;
          }
        });
    },
    openAssignmentDetails(id) {
      this.$router.push({ name: "user.assignment-detail", params: { id: id } });
    },
    openSocialPost(id) {
      this.$router.push({ name: "user.wall", params: { id: id } });
    },
    openCollectionDetails(id) {
      this.$router.push({ name: "user.collection-detail", params: { id: id } });
    },
    openCatalogDetails(id) {
      this.$router.push({ name: "user.eduction-detail", params: { id: id } });
    },
    openProviderDetails(result) {
      axios.get(process.env.VUE_APP_BACKEND_APP_URL + "/api/provider-content-show/" + result.provider + "/" + result.id, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.secureLocalStorage("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.$router.push({ name: "user.assignment-detail", params: { id: response.data.assignment_id } });
          } else {
            // TODO kontrol edilmeli 404 gelebilyior
          }
        });
    },
    getAllResults() {
      this.getCollectionResult()
      this.getSocialPosts()
      this.getAssignmentResult()
      this.getSearchProviderResult()
      this.getCatalogResult()

    },
    onSubmitSearch(eve) {
      this.$refs.searchInput.blur();
      this.modal = false;
      this.$emit("searchFor", this.search);
    },
  },
  mounted() {
    if (this.$route.query.search) {
      this.search = this.$route.query.search;
      this.onSubmitSearch();
    } else {
      this.getAllResults();
    }

    $('.searchbar').css('padding', '5rem');
    $('.searchbar').css('border-radius', '8px');
    $('.searchbar').css('background-size', '115%');
    $('.searchbar').css('background-position', 'bottom right');

    if (this.company && this.company.search_bg_image) {
      $('.searchbar').css('background', 'url(' + this.company.search_bg_image_link + ') no-repeat right center');
      $('.searchbar').css('background', 'url(' + this.company.search_bg_image_link + ') no-repeat right center');
      $('.searchbar').css('background-size', '115%!important');
    }
  },
  watch: {
    company(item) {
      if (item && item.search_bg_image) {
        setTimeout(() => {
          $('.searchbar').css('background', 'url(' + item.search_bg_image_link + ') no-repeat right center');
          $('.searchbar').css('background', 'url(' + item.search_bg_image_link + ') no-repeat right center');
          $('.searchbar').css('background-size', '115%');

        }, 500)
      }
    },
    searchValue(val, prev) {
      if (val != prev) this.search = val;
    },
    search(val, prev) {
      if (val != prev) this.debouncedFilterSearch();
    },
  },
  created: function () {
    this.debouncedFilterSearch = _.debounce(this.getAllResults, 500);
  },
};
</script>

<style>
.z-100 {
  z-index: 100 !important;
}

.section-header-left h2 {
  text-transform: none !important;
}
</style>
